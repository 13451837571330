import { Disbursement, CreateDisbursement } from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";
import { WithCreate } from "./mixins/WithCreate";
import { WithGet } from "./mixins/WithGet";
import { WithDelete } from "./mixins/WithDelete";

class Endpoint extends BaseEndpoint("disbursements") {}

const withCreate = WithCreate<
  typeof Endpoint,
  Disbursement,
  CreateDisbursement
>(Endpoint);

const withDelete = WithDelete(withCreate);

export const DisbursementEndpoint = WithGet<typeof withDelete, Disbursement>(
  withDelete,
);
