import {
  BillPage,
  ContractPolicy,
  CreatePerson,
  EntityIdentifier,
  Outgoing,
  Person,
  PersonPage,
  UpdatePerson,
} from "../dtos";
import { CreatePatientByQuickWay } from "../dtos/CreatePatientByQuickWay";
import { CreatePersonWithPolicy } from "../dtos/CreatePersonWithPolicy";
import { BaseEndpoint } from "./BaseEndpoint";
import { WithCreate } from "./mixins/WithCreate";
import { WithSearchableGetPage } from "./mixins/WithSearchableGetPage";
import { WithUpdate } from "./mixins/WithUpdate";
import { GetBillsQuery } from "./query/GetBillsQuery";
import { GetPersonsQuery } from "./query/GetPersonsQuery";

export class Endpoint extends BaseEndpoint("persons") {
  getPersonOutgoing(personId: string): Promise<Outgoing> {
    return this.request(`${personId}/outgoing`);
  }

  getPersonBills(personId: string, query?: GetBillsQuery): Promise<BillPage> {
    return this.request(`${personId}/bills`, {
      query,
    });
  }

  getPersonContractPolicies(personId: string): Promise<ContractPolicy[]> {
    return this.request(`${personId}/contract-policies`);
  }

  getPersonRelationships(personId: string): Promise<Person[]> {
    return this.request(`${personId}/relationships`);
  }

  createPersonRelationship(
    personId: string,
    body: EntityIdentifier,
  ): Promise<Person> {
    return this.request(`${personId}/relationships`, {
      body,
      method: "POST",
    });
  }

  deletePersonRelationship(
    personId: string,
    relationshipId: string,
  ): Promise<void> {
    return this.request(`${personId}/relationships/${relationshipId}`, {
      method: "DELETE",
    });
  }

  createPatientByQuickWay(
    body: CreatePersonWithPolicy,
  ): Promise<CreatePatientByQuickWay> {
    return this.request(`createPatientByQuickWay`, {
      body,
      method: "POST",
    });
  }

  getAll(query?: Pick<GetPersonsQuery, "includeArchived">): Promise<Person[]> {
    return this.request("all", { query });
  }
}

const withSearch = WithSearchableGetPage<
  typeof Endpoint,
  Person,
  PersonPage,
  GetPersonsQuery
>(Endpoint);

const withCreate = WithCreate<typeof withSearch, Person, CreatePerson>(
  withSearch,
);

const withUpdate = WithUpdate<typeof withCreate, Person, UpdatePerson>(
  withCreate,
);

export const PersonEndpoint = withUpdate;
